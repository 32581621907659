import { ReactNode } from "react";
import { Stack, Typography, Button, useTheme, Divider } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

interface ButtonUpDownProps {
  isMobile?: boolean;
  step?: number;
  totalSteps?: number;
  onClickPrev?: Function;
  onClickNext?: Function;
}

const StyledButton = (props: {children: ReactNode; onClick: Function; borderRadius: string }) => (
  <Button
    onClick={() => props.onClick?.()}
    disableRipple
    sx={{
      maxWidth: 28,
      maxHeight: 28,
      minWidth: 42,
      minHeight: 42,
      backgroundColor: (theme) => theme.vars.palette.secondary.dark,
      "&:hover": {
        background: "#6E6A72",
      },
      "&:active": {
        background: "#39373F",
      },
      borderRadius: props.borderRadius,
    }}>
    {props.children}
  </Button>
);

export const ButtonUpDown = ({
  step,
  totalSteps,
  isMobile = false,
  onClickNext,
  onClickPrev,
}: ButtonUpDownProps) => {
  const theme = useTheme();
  if (!step || !totalSteps) {
    return <></>;
  }
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {!isMobile && (
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
          {`${step} / ${totalSteps}`}
        </Typography>
      )}
      <Stack direction="row">
        <StyledButton
          onClick={onClickNext?.()}
          borderRadius= "2px 0px 0px 2px">
          <ArrowDropUp
            style={{
              height: "40px",
              width: "32px",
              color: theme.vars.palette.secondary.main,
            }}
          />
        </StyledButton>
        <Divider
          variant="middle"
          sx={{
            m: "0px",
            width: "1px",
            backgroundColor: theme.vars.palette.secondary.main,
          }}
        />
        <StyledButton
          onClick={onClickPrev?.()}
          borderRadius= "0px 2px 2px 0px">
          <ArrowDropDown
            style={{
              height: "40px",
              width: "32px",
              color: theme.vars.palette.secondary.main,
            }}
          />
        </StyledButton>
      </Stack>
    </Stack>
  );
};
