import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import type {} from "@mui/material/themeCssVarsAugmentation";

const colors = {
  black: "#000000",
  white: "#FFFFFF",
  light_grey: "#EBEBEB",
  dark_grey: "#49454F",
  light_blue: "#48A8E1",
  blue: "#3B8BC2",
  dark_blue: "#18457A",
  error: "#B3261E",
  purple: "#4B1E64",
  light_purple: "#855BA9",
  light_purple_hover: "#8F68B0",
};

declare module "@mui/material/styles" {

  interface TypographyVariants {
    display: React.CSSProperties;
    headline: React.CSSProperties;
    headline2: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySmall: React.CSSProperties;
    labelMedium: React.CSSProperties;
    labelSmall: React.CSSProperties;
  }

  interface Theme {
    drawerOverflowPadding: number;
    footerLogoHeight: number;
    drawerBleed: number;
  }

  interface ThemeOptions {
    drawerOverflowPadding: number;
    footerLogoHeight: number;
    drawerBleed?: number;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display?: React.CSSProperties;
    headline?: React.CSSProperties;
    headline2?: React.CSSProperties;
    bodyLarge?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    labelMedium?: React.CSSProperties;
    labelSmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display: true;
    headline: true;
    headline2: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    labelMedium: true;
    labelSmall: true;
    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    body1: false;
    body2: false;
    subtitle1: false;
    subtitle2: false;
    overline: false;
    caption: false;
  }
}

export const theme = () =>
  extendTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    drawerOverflowPadding: 80,
    footerLogoHeight: 80,
    drawerBleed: 140,
    colorSchemes: {
      light: {
        palette: {
          text: {
            primary: colors.purple,
            secondary: colors.light_purple,
          },
          primary: {
            main: colors.purple,
            light: colors.light_purple,
          },
          secondary: {
            light: colors.white,
            main: colors.light_grey,
            dark: colors.dark_grey,
          },
          background: {
            default: colors.white,
          },
          error: {
            main: colors.error,
          },
        },
      },
      dark: {
        palette: {
          text: {
            primary: colors.white,
            secondary: colors.light_grey,
          },
          primary: {
            main: colors.purple,
            light: colors.light_purple,
          },
          secondary: {
            light: colors.white,
            main: colors.light_grey,
            dark: colors.dark_grey,
          },
          background: {
            default: colors.white,
          },
          error: {
            main: colors.error,
          },
        },
      },
    },
    typography: {
      //this is actually bugged for custom themes
      //https://github.com/mui/material-ui/issues/35939
      //fontFamily: ["Karbon", "Verdana", "Geneva", "sans-serif"].join(","),
      display: {
        fontFamily: "Poppins",
        fontSize: "1.75rem",
        fontWeight: 600,
        lineHeight: "1.3",
        letterSpacing: "0.07813rem",
      },
      headline: {
        fontSize: "1.4375rem",
        fontWeight: 600,
        lineHeight: "1.4",
        letterSpacing: "0.08625rem",
        textTransform: "capitalize",
      },
      headline2: {
        fontSize: "1.4375rem",
        fontWeight: 700,
        lineHeight: "2.0125rem",
      },
      bodyLarge: {
        fontSize: "1.125rem",
        fontWeight: "400",
        lineHeight: "1.6875rem",
      },
      bodyMedium: {
        fontFamily: "Poppins",
        fontSize: "1rem",
        lineHeight: "1.5",
        letterSpacing: "0.07813rem",
      },
      bodySmall: {
        fontFamily: "Helvetica",
        fontSize: "0.875rem",
        lineHeight: "1.5",
      },
      labelMedium: {
        fontFamily: "Helvetica",
        fontSize: "1.125rem",
        fontWeight: "normal",
        lineHeight: "1.5",
      },
      labelSmall: {
        fontFamily: "Helvetica",
        fontSize: "1rem",
        fontWeight: "normal",
        lineHeight: "1.5",
      },
      button: {
        fontSize: "1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        lineHeight: "1rem",
      },
    },
    components: {
      MuiStack: {
        defaultProps: {
          sx: { zIndex: "inherit" },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            "&: hover": {
              backgroundColor: colors.light_purple_hover,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          color: "text.primary",
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              fontWeight: "bold",
              fontSize: "1rem",
              color: theme.vars.palette.primary.dark,
              textDecoration: "none",
              textTransform: "uppercase",
            }),
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            backgroundImage: "none",
            boxShadow: "none",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {},
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {},
          root: {
            fontWeight: "bold",
            "& fieldset": {
              borderRadius: 20,
              overflow: "hidden",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
            color: "white",
            backgroundColor: "#474747",
            borderRadius: 20,
            padding: 10,
            boxShadow: "0 2px 9px 0 rgba(0, 0, 0, 0.15)",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "white",
            padding: 10,
            color: "black",
            boxShadow:
              "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);",
          },
          arrow: {
            color: "white",
          },
        },
      },
    },
  });
