import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ScopedCssBaseline } from "@mui/material";
import ReactGA from "react-ga4";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { getConfig } from "./common/config";
import { addOnClickToNavLinks, showElements } from "./utils/page";

const {
  ga: { trackingId },
} = getConfig();

ReactGA.initialize([
  {
    trackingId,
  },
]);

addOnClickToNavLinks(() => {
  if (!window.uneeqSessionLive) {
    return;
  }

  showElements();
  window.uneeqEndSession?.();
});

createRoot(document.getElementById("digital-human")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ScopedCssBaseline sx={{ height: 1 }}>
        <App />
      </ScopedCssBaseline>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
