import { Box, Stack } from "@mui/material";
import { useShallowCompareEffect } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

import { useThemeUtils } from "../hooks/theme";
import { CustomDrawer } from "../components/CustomDrawer/CustomDrawer";
import { SlideContent } from "../components/SlideContent";
import { ButtonUpDown } from "../components/Buttons/ButtonUpDown";
import { useHostedInterface } from "../hooks/useHostedInterface";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { getShowContent, setShowContent } from "../store/app";
import { OnScreenContent } from "../types/uneeq";

interface OnScreenContentProps {
  onScreenContent: OnScreenContent;
}

const DesktopLayout = ({ onScreenContent }: OnScreenContentProps) => (
  <>
    <Stack
      component={motion.div}
      sx={{
        position: "absolute",
        zIndex: 1,
        right: "6%",
        top: "15%",
        width: "32%",
        height: "75%",
        justifyContent: "center",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <SlideContent onScreenContent={onScreenContent} />
    </Stack>
    <Stack
      sx={{
        position: "absolute",
        right: "5%",
        bottom: 0,
        zIndex: 1600,
      }}
    >
      <ButtonUpDown step={onScreenContent.step} totalSteps={onScreenContent.totalSteps} />
    </Stack>
  </>
);

const MobileLayout = ({ onScreenContent }: OnScreenContentProps) => (
  <Box
    position="absolute"
    top="10px"
    height="calc(100% - 82px)" // Height of the text box in mobile mode (72px) - top position
    sx={{
      pointerEvents: "none",
      zIndex: 2,
    }}
  >
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        background: "linear-gradient(0deg, rgba(0,0,0,.25) 0%, rgba(255,255,255,0.01) 100%)",
        height: "5px",
        bottom: 0,
        zIndex: 1,
      }}
    />
    <CustomDrawer>
      <SlideContent onScreenContent={onScreenContent} />
      <Stack
        sx={{
          paddingTop: "16px",
          alignItems: "end",
          zIndex: 1600,
        }}
      >
        <ButtonUpDown step={onScreenContent?.step} totalSteps={onScreenContent?.totalSteps} />
      </Stack>
    </CustomDrawer>
  </Box>
);

export const OnScreenContentDisplay = () => {
  const [avatarStartedSpeaking, setAvatarStartedSpeaking] = useState(false);

  const { isMobile } = useThemeUtils();
  const dispatch = useAppDispatch();

  const { hostedCustomData, hostedLive, hostedAvatarSpeaking } = useHostedInterface();
  const { onScreenContent } = hostedCustomData;
  const showContent = useAppSelector(getShowContent);
  const onScreenContentAvailable = !!onScreenContent;

  useShallowCompareEffect(() => {
    if (!onScreenContent) {
      return;
    }

    dispatch(setShowContent(true));
  }, [onScreenContent]);

  useEffect(() => {
    if (!hostedAvatarSpeaking) {
      return;
    }

    if (!showContent) {
      setAvatarStartedSpeaking(false);
      return;
    }

    setAvatarStartedSpeaking(true);
  }, [hostedAvatarSpeaking, showContent]);

  const showOnScreenContent = showContent && onScreenContentAvailable && hostedLive && avatarStartedSpeaking;

  return (
    <AnimatePresence>
      {showOnScreenContent &&
        (isMobile ? (
          <MobileLayout onScreenContent={onScreenContent} />
        ) : (
          <DesktopLayout onScreenContent={onScreenContent} />
        ))}
    </AnimatePresence>
  );
};
