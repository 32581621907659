import { useState } from "react";
import { Stack, TextField, useTheme, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TextInputField } from "../types/uneeq";
import { useHostedInterface } from "../hooks/useHostedInterface";

interface TextInputProps {
  textInput: TextInputField;
}

export const TextInput = ({ textInput: { placeholderText, buttonText } }: TextInputProps) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [text, setText] = useState<string>("");
  const { t } = useTranslation();
  const { uneeqAsk } = useHostedInterface();
  const theme = useTheme();

  const validate = (text: string) => {
    if (text === "") {
      setError(t("textInput.noInput"));
      return false;
    }
    if (text?.length > 30) {
      setError(t("textInput.tooLong"));
      return false;
    }
    setError(undefined);
    return true;
  };

  return (
    <Stack alignItems="start" gap={2}>
      <TextField
        color="primary"
        fullWidth
        id="standard-basic"
        label={placeholderText}
        variant="standard"
        error={!!error}
        helperText={error}
        onChange={(e) => {
          setText(e.target.value);
          validate(e.target.value);
        }}
        sx={{
          input: {
            color: theme.vars.palette.primary.main,
          },
          "&& .MuiInput-root:hover::before": {
            borderColor: theme.vars.palette.primary.light,
          },
          ".MuiInputLabel-root": {
            color: "#9B95A3",
          },
          ".MuiInput-underline:before": {
            borderBottom: `solid 2px ${theme.vars.palette.primary.main}`,
          },
          ".MuiInput-underline:after": {
            borderBottom: `solid 2px ${theme.vars.palette.primary.light}`,
          },
        }}
      />
      <Button
        sx={{
          py: 2,
          px: 3,
          ":disabled": {
            backgroundColor: "E0E0E0",
            color: "#8B8B8B",
          },
        }}
        disabled={!!error}
        onClick={() => {
          if (validate(text)) {
            uneeqAsk(text);
          }
        }}>
        {buttonText}
      </Button>
    </Stack>
  );
};
