import { Stack, Typography, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { loadDefaultJapaneseParser } from "budoux";

export const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const parser = loadDefaultJapaneseParser();

  return (
    <Box
      height="auto"
      bgcolor={theme.vars.palette.primary.main}
    >
      <Stack sx={{ py: 2.5, px: 6 }}>
        <Typography
          variant="bodySmall"
          dangerouslySetInnerHTML={{
            __html: parser.translateHTMLString(
              t("disclaimerBanner.disclaimer")
            ),
          }}
        ></Typography>
      </Stack>
    </Box>
  );
};
