const querySelector = "#about, #flow, #voice, #contact";

export const hideElements = () => {
  const elements = document.querySelectorAll(querySelector);

  elements.forEach((element) => {
    (element as HTMLElement).style.display = "none";
  });
};

export const showElements = () => {
  const elements = document.querySelectorAll(querySelector);

  elements.forEach((element) => {
    (element as HTMLElement).style.display = "block";
  });
};

export const addOnClickToNavLinks = (clickHandler: (event: MouseEvent) => void) => {
  // Get the navigation element by its class
  const nav = document.querySelectorAll(".anc-nav-sticy, .kakehashi-menu");

  if (nav.length === 0) {
    console.error("Navigation elements not found");
    return;
  }

  // Select all anchor tags within all navigation elements
  const links = Array.from(nav).reduce(
    (acc, element) => [...acc, ...Array.from(element.querySelectorAll("a"))],
    [] as HTMLAnchorElement[]
  );

  // Add the click handler to each link
  links.forEach((link) => {
    link.addEventListener("click", clickHandler);
  });
};
