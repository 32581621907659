import { UneeqInteractionsOptions } from "../types/uneeq";

export const getConfig = () => ({
  uneeq: {
    avatarName: () => process.env["REACT_APP_AVATAR_NAME"],
    // Hosted Frontend
    hostedSrc: () => process.env["REACT_APP_UNEEQ_HOSTED_SRC"],
    uneeqInteractionsOptions: (isMobile = false): UneeqInteractionsOptions => ({
      initLoadHandler: false,
      personaShareId: process.env["REACT_APP_UNEEQ_HOSTED_PERSONA_SHARE_ID"]!,
      enableMicrophone: false,
      showUserInputInterface: true,
      displayCallToAction: false,
      playWelcome: true,
      layoutMode: "contained",
      voiceInputMode: "SPEECH_RECOGNITION",
      ctaThumbnailUrl: "https://storage.googleapis.com/assets-erleada-jp/cta-image.png",
      autoStart: false,
      speechToTextLocales: "ja-JP",
      showClosedCaptions: false,
      languageStrings: {
        default: {
          callToActionText: "案内人の篠原が「かけはし」についてより詳しくお話しします",
          textInputPlaceholder: "話すか入力する",
          errorText: "エラーが発生しました。",
          showContentToolTip: "コンテンツを表示",
          sendQuestionButtonToolTip: "メッセージを送る",
          startRecordingButtonToolTip: "録音を開始",
          unmuteMicrophoneToolTip: "マイクをオンにする",
          muteMicrophoneToolTip: "マイクをオフにする",
          microphonePermissionNotAllowedButtonToolTip: "マイクの許可が許可されていません",
          microphoneEnabledHintTitle: "マイクが有効",
          microphoneEnabledHintText: "私に話しかけてください、聞いています。",
          microphoneEnabledHintTitleOverlay: "マイクが有効",
          microphoneNotAllowedMessage:
            "マイクの使用が許可されませんでした。ブラウザの設定でマイクへのアクセスを許可して、もう一度お試しください。",
          recordingTapToStart: "録音するにはタップして、送信するには再度タップしてください",
          recordingSpeakNowPrompt: "今話してください",
          recordingTapStopWhenDone: "終了する時は停止をタップしてください",
          recordingStopButtonToolTip: "録音を停止",
          recordingRequestingMicrophonePrompt: "マイクをリクエスト中",
          settingsExitButton: "終了",
          confirmExitDescription: "本当に終了しますか？",
          confirmExitStayButton: "残る",
          confirmExitEndButton: "終了",
          hideContentButton: "コンテンツを隠す",
          hideDigitalHumanButton: "デジタルヒューマンを隠す",
        },
      },
      customStyles: `body > app-root > mat-drawer-container > mat-drawer-content > div > app-avatar-video > div { padding: 0px !important; }
                    .settings-btn { display: none; }
                    .input-container { height: 40px !important; }
                    #recordStartBtn { height: 32px !important; width: 32px !important; }
                    #recordStartBtn > mat-icon { font-size: 24px !important; height: 24px !important; width: 24px !important; }
                    #questionInput { font-size: 18px !important; }
                    #sendQuestionBtn {height: 30px; width: 30px}
                    #sendQuestionBtn > mat-icon {width: 20px; height: 23px; font-size: 23px; }`,
      cameraPosition: isMobile
        ? {
          camera_position_vertical: 0,
          camera_position_horizontal: 0,
          camera_position_distance: 0,
        }
        : {
          camera_position_vertical: -0.5,
          camera_position_horizontal: -0.5,
          camera_position_distance: 0.7,
        },
    }),
    launchHostedAtStart: true,
  },
  cmiDate: "1/12/2021",
  ga: {
    trackingId: "G-FDXFD4EDGM",
  },
});
