import { uneeqHostedSlice } from "./slice";

export const {
  setSessionState,
  setHostedVisible,
  setLayoutMode,
  setHostedShowUserInput,
  setHostedAvatarSpeaking,
  setHostedAvatarThinking,
  setHostedCustomData,
} = uneeqHostedSlice.actions;
