import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "./redux";

import { UneeqSessionState } from "../types/uneeq";
import { setHostedAvatarThinking, setHostedVisible } from "../store/uneeq/actions";
import {
  selectHostedAvatarSpeaking,
  selectHostedAvatarThinking,
  selectHostedCustomData,
  selectHostedVisible,
  selectLayoutMode,
  selectSessionState,
  selectShowUserInputHosted,
} from "../store/uneeq";

export const useHostedInterface = () => {
  const { uneeqAsk, uneeqStartSession, uneeqEndSession } = window;
  const dispatch = useAppDispatch();
  const hostedSessionState = useAppSelector(selectSessionState);
  const hostedAvatarSpeaking = useAppSelector(selectHostedAvatarSpeaking);
  const hostedAvatarThinking = useAppSelector(selectHostedAvatarThinking);
  const hostedVisible = useAppSelector(selectHostedVisible);
  const layoutMode = useAppSelector(selectLayoutMode);
  const showUserInput = useAppSelector(selectShowUserInputHosted);
  const hostedCustomData = useAppSelector(selectHostedCustomData);

  // Statuses
  const hostedInitialised = hostedSessionState === UneeqSessionState.Initial;
  const hostedLoading = hostedSessionState === UneeqSessionState.WaitingToStart;
  const hostedLive = hostedSessionState === UneeqSessionState.Live;
  const hostedReady = hostedSessionState !== undefined;
  const showHosted = hostedLive && hostedVisible;

  const launchHostedAvatar = useCallback(() => {
    dispatch(setHostedVisible(true));
    dispatch(setHostedAvatarThinking(true));
    uneeqStartSession?.();
  }, [dispatch, uneeqStartSession]);

  const hideHostedAvatar = useCallback(() => {
    dispatch(setHostedVisible(false));
  }, [dispatch]);

  return {
    hostedAvatarSpeaking,
    hostedAvatarThinking,
    hostedCustomData,
    showHosted,
    hostedLoading,
    hostedLive,
    layoutMode,
    showUserInput,
    hostedSessionState,
    hostedInitialised,
    hostedReady,
    launchHostedAvatar,
    hideHostedAvatar,
    uneeqAsk,
    uneeqEndSession,
  };
};
