import { useEffect, useState } from "react";
import { Box, Button, Stack, useTheme } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import { Footer } from "../components/Footer";
import { RatingModal } from "../components/modals/RatingModal";
import { OnScreenContentDisplay } from "../components/OnScreenContentDisplay";
import { useHostedInterface } from "../hooks/useHostedInterface";
import { useThemeUtils } from "../hooks/theme";

interface InteractionPageProps {
  hostedLive: boolean;
  hidden: boolean;
}

export const InteractionPage = ({ hidden = false }: InteractionPageProps) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeUtils();
  const theme = useTheme();
  const [openRating, setOpenRating] = useState(false);
  const { hostedCustomData } = useHostedInterface();
  const { conversationEnd, disclaimer } = hostedCustomData;
  const isKeyboardOpen = useDetectKeyboardOpen();

  useEffect(() => {
    if (!conversationEnd) {
      return;
    }

    setOpenRating(true);
  }, [conversationEnd]);

  useEffect(() => {
    if (isKeyboardOpen) {
      document.getElementById("footer")!.style.display = "none";
    } else {
      document.getElementById("footer")!.style.display = "block";
    }
  }, [isKeyboardOpen]);

  return (
    <>
      <Stack height={hidden ? 0 : isMobile ? "auto" : "calc(100vh - var(--desktop-header-height));"}>
        <Box
          id="uneeqContainedLayout"
          sx={{
            ...(isMobile && { height: "calc(100svh - 68px)" }),
            flexGrow: 1,
            position: "relative",
            background: "#d1cadb",
          }}
        >
          {!hidden && (
            <>
              <Button
                variant="text"
                sx={{
                  position: "absolute",
                  top: isMobile ? 20 : 50,
                  right: 20,
                  color: theme.vars.palette.primary.main,
                  gap: 1,
                  zIndex: "1",
                }}
                onClick={() => setOpenRating(true)}
              >
                {t("leaveChat")}
                <ExitToApp />
              </Button>
              <OnScreenContentDisplay />
            </>
          )}
        </Box>
        {disclaimer && !isKeyboardOpen && <Footer />}
      </Stack>
      <RatingModal open={openRating} setOpen={setOpenRating} />
    </>
  );
};
