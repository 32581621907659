import { createSlice } from "@reduxjs/toolkit";

export interface AppState {
  showContent: boolean;
}

const initialState: AppState = {
  showContent: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setShowContent: (state, action) => {
      state.showContent = action.payload;
    },
  },
});
