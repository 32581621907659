import { useState } from "react";
import {
  Button,
  Dialog,
  Typography,
  Rating,
  Stack,
  Box,
  useTheme,
  useMediaQuery,
  IconContainerProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { loadDefaultJapaneseParser } from "budoux";
import ReactGA from "react-ga4";

import { useHostedInterface } from "../../hooks/useHostedInterface";
import { useThemeUtils } from "../../hooks/theme";

interface RatingModalProps {
  open: boolean;
  setOpen: Function;
}

export const RatingModal = ({ open, setOpen }: RatingModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [rating, setRating] = useState(0);
  const { uneeqEndSession } = useHostedInterface();
  const { isMobile } = useThemeUtils();
  const parser = loadDefaultJapaneseParser();
  const wrapped = useMediaQuery("(max-width:691px)");

  const handleClose = () => {
    setOpen(false);
  };

  const handleChatExit = () => {
    uneeqEndSession?.();
    handleClose();
  };

  const submitRating = (event: any, newValue: number | null) => {
    //insert logic for submitting feedback to db/lambda, etc...
    if (!newValue) {
      return;
    }

    setRating(newValue);
    ReactGA.event({ category: "rating", action: "submitRating", value: newValue });
  };

  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return (
      <Box
        {...other}
        sx={{
          color: theme.vars.palette.secondary.light,
          border: `solid 1px ${theme.vars.palette.primary.dark}`,
          bgcolor: theme.vars.palette.primary.dark,
          fontSize: "16px",
          fontWeight: "bold",
          height: "44px",
          width: "42px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: "4px",
          borderRadius: "5px",
        }}
      >
        {value - 1}
      </Box>
    );
  };

  return (
    <>
      <Dialog
        data-mui-color-scheme="light"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "800px",
          },
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            py: "40px",
            px: isMobile ? "32px" : "96px",
            gap: 3,
          }}
        >
          <Typography
            sx={{
              position: "relative",
              textAlign: "center",
              "&::before": {
                bgcolor: theme.vars.palette.primary.main,
                content: "''",
                position: "absolute",
                bottom: "-8px",
                left: "25%",
                height: "2px",
                width: "50%",
              },
            }}
            variant="bodyLarge"
            dangerouslySetInnerHTML={{
              __html: parser.translateHTMLString(t("rating.title")),
            }}
          ></Typography>
          <Stack>
            <Stack
              sx={{
                flexDirection: wrapped ? "column" : "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Typography color={theme.vars.palette.primary.light}>
                {wrapped ? t("rating.lowRatingMobile") : t("rating.lowRating")}
              </Typography>
              <Typography color={theme.vars.palette.primary.light}>
                {wrapped ? t("rating.highRatingMobile") : t("rating.highRating")}
              </Typography>
            </Stack>
            <Stack
              sx={{
                alignItems: "center",
                marginX: "-4px",
              }}
            >
              <Rating
                defaultValue={5}
                max={11}
                IconContainerComponent={IconContainer}
                sx={{
                  flexWrap: "wrap",
                  width: "100%",
                  "& .MuiRating-iconEmpty": {
                    bgcolor: theme.vars.palette.secondary.light,
                    color: "black",
                  },
                }}
                name="simple-controlled"
                value={rating}
                onChange={submitRating}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                flex: "0 1 auto",
                py: "16px",
                bgcolor: theme.vars.palette.secondary.light,
                border: `${theme.vars.palette.primary.dark} 1px solid`,
                color: theme.vars.palette.primary.dark,
                "&: hover": {
                  color: theme.vars.palette.secondary.light,
                  bgcolor: theme.vars.palette.primary.main,
                },
              }}
              onClick={handleChatExit}
            >
              {t("leaveChat")}
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                flex: "0 1 auto",
                p: "16px 24px",
              }}
            >
              {t("backToChat")}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
