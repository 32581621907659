import { CSSProperties, ReactChild, Children } from "react";
import { Carousel } from "react-responsive-carousel";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const arrowStyles: CSSProperties = {
  position: "absolute",
  color: "white",
  zIndex: 2,
  top: "calc(50% - 25px)",
  width: 50,
  height: 50,
  cursor: "pointer",
  stroke: "white",
  strokeWidth: "0.5px",
};
const indicatorStyles: CSSProperties = {
  background: "transparent",
  width: 8,
  height: 8,
  borderRadius: "8px",
  border: "1px solid white",
  display: "inline-block",
  margin: "0 8px",
  transition: "all .5s",
  pointerEvents: "auto",
};

const customRenderItem = (item: any, props: any) => (
  <item.type {...item.props} {...props} />
);

export const CustomCarousel = ({
  children,
}: {
  children: ReactChild[] | undefined;
}) => {
  if (Children.count(children) === 1) {
    return <>{children}</>;
  }
  return (
    <Carousel
      autoPlay={false}
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev) => (
        <ChevronLeft
          type="button"
          onClick={onClickHandler}
          style={{
            ...arrowStyles,
            ...(!hasPrev && { fill: "none" }),
            left: 15,
          }}
        ></ChevronLeft>
      )}
      renderArrowNext={(onClickHandler, hasPrev) => (
        <ChevronRight
          type="button"
          onClick={onClickHandler}
          style={{
            ...arrowStyles,
            ...(!hasPrev && { fill: "none" }),
            right: 15,
          }}
        ></ChevronRight>
      )}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: "white" }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
      renderItem={customRenderItem}
    >
      {children}
    </Carousel>
  );
};
