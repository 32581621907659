import { Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

export const CustomDrawerTab = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flex: "none",
        pt: "8px",
        pb: "24px",
      }}
    >
      <Box
        sx={{
          width: 32,
          height: 4,
          backgroundColor: theme.vars.palette.secondary.dark,
          borderRadius: 2,
          opacity: 0.5,
        }}
      />
    </Stack>
  );
};
