import { Button, Typography, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";
import { loadDefaultJapaneseParser } from "budoux";

import { useHostedInterface } from "../../hooks/useHostedInterface";
import { useAppDispatch } from "../../hooks/redux";
import { setShowContent } from "../../store/app";

interface PromptButtonProps extends PropsWithChildren {
  children: string;
  utterance: string;
}

export const PromptButton = ({ children, utterance }: PromptButtonProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { uneeqAsk } = useHostedInterface();
  const parser = loadDefaultJapaneseParser();

  return (
    <Button
      variant="contained"
      fullWidth
      sx={{
        px: 3,
        py: 3,
        textAlign: "center",
        backgroundColor: theme.vars.palette.primary.light,
      }}
      onClick={() => {
        dispatch(setShowContent(false));
        uneeqAsk(utterance);
      }}
    >
      <Typography dangerouslySetInnerHTML={{ __html: parser.translateHTMLString(children) }} variant="labelSmall" sx={{ fontWeight: "bold" }}></Typography>
    </Button>
  );
};
