import { useEvent } from "react-use";
import { useCallback, useEffect } from "react";
import { useScript } from "usehooks-ts";

import { useAppDispatch } from "./redux";
import { useHostedInterface } from "./useHostedInterface";
import { useThemeUtils } from "./theme";

import {
  AvatarAnswerMessage,
  HostedUneeqMessage,
  CustomData,
  UneeqEvent,
  UneeqSessionMessage,
  UneeqSessionState,
} from "../types/uneeq";
import { getConfig } from "../common/config";
import {
  setHostedAvatarSpeaking,
  setHostedAvatarThinking,
  setHostedCustomData,
  setSessionState,
} from "../store/uneeq/actions";

const config = getConfig();

export const useHostedDigitalHuman = () => {
  const { isMobile } = useThemeUtils();
  const { uneeqSetLayoutMode, uneeqSetShowUserInputInterface } = window;
  const dispatch = useAppDispatch();
  const { hostedSessionState, layoutMode, showUserInput } = useHostedInterface();
  const uneeqFrame = document.getElementById("uneeqFrame");

  window.uneeqInteractionsOptions = config.uneeq.uneeqInteractionsOptions(isMobile);

  const status = useScript(config.uneeq.hostedSrc() ?? null);

  useEffect(() => {
    if (uneeqFrame || status !== "ready") {
      return;
    }

    uneeq.init();
  }, [uneeqFrame, status]);

  const onUneeqMessage = useCallback(
    (event: UneeqEvent) => {
      const message = event.detail as HostedUneeqMessage;

      if (message.uneeqMessageType === "ReadyToStart") {
        dispatch(setSessionState(UneeqSessionState.Initial));
      }

      if (message.uneeqMessageType === "SessionStateUpdate") {
        const { state } = message as UneeqSessionMessage;
        dispatch(setSessionState(state));
      }

      if (message.uneeqMessageType === "StartedSpeaking") {
        dispatch(setHostedAvatarSpeaking(true));
        dispatch(setHostedAvatarThinking(false));
      }

      if (message.uneeqMessageType === "FinishedSpeaking") {
        dispatch(setHostedAvatarSpeaking(false));
      }

      if (message.uneeqMessageType === "AvatarAnswer") {
        const { answerAvatar } = message as AvatarAnswerMessage;

        try {
          const { instructions } = JSON.parse(answerAvatar);
          const { customData: hostedCustomData } = instructions as { customData: CustomData };

          if (!hostedCustomData) {
            return;
          }

          dispatch(setHostedCustomData(hostedCustomData));
        } catch (e) {
          console.warn("Could not parse answerAvatar payload");
          console.warn(e);
        }
      }
    },
    [dispatch]
  );

  useEvent("UneeqMessage", onUneeqMessage);

  // Layout Mode Control
  useEffect(() => {
    if (hostedSessionState === undefined) {
      return;
    }

    uneeqSetLayoutMode?.(layoutMode);
  }, [layoutMode, uneeqSetLayoutMode, hostedSessionState]);

  // Input Control
  useEffect(() => {
    uneeqSetShowUserInputInterface?.(showUserInput);
  }, [showUserInput, uneeqSetShowUserInputInterface]);
};
