import { PropsWithChildren, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { PanInfo, motion } from "framer-motion";
import { useMeasure } from "react-use";

import { CustomDrawerTab } from "./CustomDrawerTab";

interface CustomDrawerProps extends PropsWithChildren {
  dragThreshold?: number;
  open?: boolean;
}

export const CustomDrawer = ({
  children,
  dragThreshold = 30,
  open = false,
}: CustomDrawerProps) => {
  const theme = useTheme();
  const [measureRef, { height }] = useMeasure();
  const closePosition = height - theme.drawerBleed;
  const openPosition = 0;

  const [yOffset, setYOffset] = useState(closePosition);

  useEffect(() => {
    setYOffset(open ? openPosition : closePosition);
  }, [open, closePosition]);

  const onDragEnd = (event: PointerEvent, info: PanInfo) => {
    if (info.offset.y > dragThreshold) {
      return setYOffset(closePosition);
    }

    if (info.offset.y < -dragThreshold) {
      return setYOffset(openPosition);
    }

    setYOffset(yOffset + 0.0001); // This is so that the drawer will re-render with new value
  };

  return (
    <Box
      ref={measureRef}
      sx={{
        height: 1,
        width: "calc(100vw - 20px)",
        marginLeft: "10px",
        overflow: "hidden",
        pointerEvents: "none",
      }}
    >
      <motion.div
        style={{
          height: `calc(100% + ${theme.drawerOverflowPadding}px)`,
          width: "100%",
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.5",
          border: `solid rgba(75, 30, 100, 0.15) 8px`,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          display: "flex",
          flexDirection: "column",
          pointerEvents: "auto",
        }}
        animate={{ y: yOffset }}
        initial={{ y: `calc(100% + ${theme.drawerBleed}px)` }}
        exit={{ y: `calc(100% + ${theme.drawerBleed}px)` }}
        drag="y"
        dragConstraints={{ bottom: closePosition, top: openPosition }}
        dragElastic={false}
        onDragEnd={onDragEnd}
      >
        <CustomDrawerTab />
        <Box p={2} overflow="scroll">
          {children}
        </Box>
      </motion.div>
    </Box>
  );
};
