import "./i18n";
import "./index.css";

import { useEffect } from "react";
import { Experimental_CssVarsProvider as CssVarsProvider, useColorScheme } from "@mui/material/styles";

import { theme } from "./theme";
import { useHostedDigitalHuman } from "./hooks/useHostedDigitalHuman";
import { useHostedInterface } from "./hooks/useHostedInterface";
import { InteractionPage } from "./pages/InteractionPage";
import { UneeqSessionState } from "./types/uneeq";
import { hideElements, showElements } from "./utils/page";
import { Avatar } from "./components/CallToAction";

export const App = () => {
  useHostedDigitalHuman();
  const { hostedLive, hostedSessionState } = useHostedInterface();
  const isLive = hostedSessionState === UneeqSessionState.Live;

  useEffect(() => {
    if (isLive) {
      window.uneeqSessionLive = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
      return hideElements();
    }

    window.uneeqSessionLive = false;
    showElements();
    document.getElementById("uneeqFrame")?.style?.setProperty("position", "fixed"); // Hosted doesn't reset otherwise
  }, [isLive]);

  const DefaultMode = () => {
    const { setMode } = useColorScheme();
    useEffect(() => {
      setMode("dark");
    }, [setMode]);
    return <></>;
  };

  return (
    <CssVarsProvider theme={theme()}>
      <DefaultMode />
      <InteractionPage hidden={!hostedLive} hostedLive={hostedLive} />
      <Avatar />
    </CssVarsProvider>
  );
};
