import { ThunkAction, Action, configureStore } from "@reduxjs/toolkit";

import { appSlice } from "./app";
import { uneeqHostedSlice } from "./uneeq";

export const reducer = {
  app: appSlice.reducer,
  uneeqHosted: uneeqHostedSlice.reducer,
};

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
