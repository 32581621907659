import { RootState } from "../store";

export const selectSessionState = (state: RootState) => state.uneeqHosted.sessionState;
export const selectHostedVisible = (state: RootState) => state.uneeqHosted.hostedVisible;
export const selectLayoutMode = (state: RootState) => state.uneeqHosted.layoutMode;
export const selectShowUserInputHosted = (state: RootState) =>
  state.uneeqHosted.hostedShowUserInput;
export const selectHostedAvatarSpeaking = (state: RootState) =>
  state.uneeqHosted.hostedAvatarSpeaking;
export const selectHostedAvatarThinking = (state: RootState) =>
  state.uneeqHosted.hostedAvatarThinking;
export const selectHostedCustomData = (state: RootState) => state.uneeqHosted.hostedCustomData;
