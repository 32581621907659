import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./lang/en.json";
import jp from "./lang/jp.json";

i18n.use(initReactI18next).init({
  lng: "jp",
  fallbackLng: "jp",
});

i18n.addResourceBundle("en", "translation", en);
i18n.addResourceBundle("jp", "translation", jp);

// eslint-disable-next-line import/no-default-export
export default i18n;
