import { createSlice } from "@reduxjs/toolkit";

import { CustomData, LayoutMode, UneeqSessionState } from "../../types/uneeq";

export interface UneeqHostedSlice {
  sessionState?: UneeqSessionState;
  welcomeReceived: boolean;
  hostedVisible: boolean;
  layoutMode: LayoutMode;
  hostedShowUserInput: boolean;
  hostedAvatarSpeaking: boolean;
  hostedAvatarThinking: boolean;
  hostedCustomData: CustomData;
}

const initialState: UneeqHostedSlice = {
  welcomeReceived: false,
  hostedVisible: false,
  layoutMode: "contained",
  hostedShowUserInput: true,
  hostedAvatarSpeaking: false,
  hostedAvatarThinking: false,
  hostedCustomData: {},
};

export const uneeqHostedSlice = createSlice({
  name: "uneeqHostedSlice",
  initialState,
  reducers: {
    setSessionState: (state, action) => {
      state.sessionState = action.payload;
    },
    setHostedVisible: (state, action) => {
      state.hostedVisible = action.payload;
    },
    setLayoutMode: (state, action) => {
      state.layoutMode = action.payload;
    },
    setHostedShowUserInput: (state, action) => {
      state.hostedShowUserInput = action.payload;
    },
    setHostedAvatarSpeaking: (state, action) => {
      state.hostedAvatarSpeaking = action.payload;
    },
    setHostedAvatarThinking: (state, action) => {
      state.hostedAvatarThinking = action.payload;
    },
    setHostedCustomData: (state, action) => {
      state.hostedCustomData = action.payload;
    },
  },
});
